<template>
  <div class="home">
<!--    <img alt="Vue logo" src="../assets/logo.png">-->
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
      请找技术人员
      <el-image style="width: 100%;" :src="src" fit="fill" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
      // eslint-disable-next-line vue/no-unused-components
    HelloWorld
  },
    setup(){
      return{
          src: require("@/assets/404.png"),
      }
    }
}
</script>
